<app-table-list
    tableName="visualization-objects"
    [data]="visualizationObjects$ | async"
    [(tableSchema)]="tableSchema"
    [showReport]="false"
    [showFilter]="false"
    [showColumnsSelection]="false"
    [showSelectionCheckbox]="false"
    [autoRows]="false"
    [selectable]="false"
    [hoverable]="false"
    (dragRowHandler)="dragRowHandler($event)"
    [draggable]="true"
>
</app-table-list>
